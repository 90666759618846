import React from "react";
import '../../components/button/button.scss'
import './work.scss';
import { Link } from 'react-router-dom';
import RichArtifacts from "../../assets/images/copilot-rich-artifacts/copilot-rich-artifacts-cover.png"
import LoopCodeBlocks from "../../assets/images/loop-code-blocks/loop-code-blocks-cover.png"
import LoopIntegration from "../../assets/images/loop-integration/loop-integration-cover.png"
import LoopPaywall from "../../assets/images/loop-paywall/loop-paywall-cover.png"
import MorganStanley from "../../assets/images/morgan-stanley/morgan-stanley-cover.png"
import Samana from "../../assets/images/samana/samana-cover.png"
import Blender from "../../assets/images/blender/blender-cover.png"
import GoStudioFX from "../../assets/images/gsfx/gsfx-cover.png"
import GoXLR from "../../assets/images/goxlr/goxlr-cover.png"
import Teamwrk from "../../assets/images/teamwrk/teamwrk-cover.png"
import Headshot from "../../assets/images/headshot.jpg";
import ArrowDown from "../../assets/icons/arrow-down.svg"
import ArrowRight from "../../assets/icons/arrow-right.svg"
import CopilotRichArtifacts from "../copilot-rich-artifacts/copilot-rich-artifacts";

const Work = () => {
  return <>
      <div className="work">
        <div className="intro">
            <img className="image-mask" src={Headshot} alt="Headshot of Ravin Perera" style={{ width: "130px" }}/>
          <div className="text">
            <h2>I'm a product designer at Microsoft, crafting innovative ways for humans to create and collaborate with AI.</h2>
            <div className="caption">
              <h5>Based in Nanaimo, BC</h5>
              <Link className="link" to="/about" style={{color: "var(--foreground4)"}}>More about me<img src={ArrowRight} alt="Arrow right" style={{ width: "24px", marginLeft: "8px"}} /></Link>
            </div>
          </div>
          <div className="projects-title">
              <h5>Recent work</h5><img src={ArrowDown} alt="Arrow down" style={{ width: "24px", marginLeft: "8px"}} />
          </div>
        </div>
        <div className="project-list">

        <div id="copilot rich artifacts" className="project" onClick={()=>window.open("/copilot-rich-artifacts","_self")}>
            <div className="item">
              <div>
                <h3 style={{marginBottom: "8px"}}>Rich artifacts in Copilot Pages</h3>
                <h5>Sep 2024 - Present</h5>
              </div>
              <div>
                <p>
                One of two lead designers for rich artifacts feature, enabling users to generate interactive charts, tables, and more using Copilot for real-time collaboration.</p>
              </div>
            </div>
            <img className="img" src={RichArtifacts} alt="Copilot rich artifacts project cover" />
            </div>

        <div id="loop code blocks" className="project" onClick={()=>window.open("/loop-code-blocks","_self")}>
            <div className="item">
              <div>
                <h3 style={{marginBottom: "8px"}}>Loop code blocks</h3>
                <h5>Feb 2023 - May 2023</h5>
              </div>
              <div>
                <p>One of the two designers who developed code blocks, enabling users to share code within their Loop workspaces and pages.</p>
              </div>
            </div>
            <img className="img" src={LoopCodeBlocks} alt="Loop code blocks cover" />
            </div>
{/* 
          <div id="loop integration in teams" className="project" onClick={()=>window.open("/loop-integration","_self")}>
            <div className="item">
              <div>
                <h3 style={{marginBottom: "8px"}}>Loop integrations in Teams</h3>
                <h5>Feb 2023 - Feb 2024</h5>
              </div>
              <div>
                <p>Led the UX of integrating Microsoft Loop features into Microsoft Teams, making it easier to collaborate on Loop content without having to leave the Teams experience.</p>
              </div>
            </div>
            <img className="img" src={LoopIntegration} alt="Loop Integration in Teams project cover" />
            </div> */}

            <div id="loop paywall" className="project" onClick={()=>window.open("/loop-paywall","_self")}>
              <div className="item">
                <div>
                  <h3 style={{marginBottom: "8px"}}>Loop paywall</h3>
                  <h5>May 2023 - Oct 2023</h5>
                </div>
                <div>
                  <p>One of two designers that designed the Loop premium experience and the freemium experience for enterprise and stand alone customers.</p>
                </div>
              </div>
              <img className="img" src={LoopPaywall} alt="Loop Paywall project cover" />
            </div>

            <div id="morgan stanley" className="project" onClick={()=>window.open("/morgan-stanley","_self")}>
              <div className="item">
                <div>
                  <h3 style={{marginBottom: "8px"}}>Morgan Stanley</h3>
                  <h5>Dec 2020 - Feb 2022</h5>
                </div>
                <div>
                  <p>Lead designer for the new retirement platform by Morgan Stanley. This experience helps clients track retirement goals to help facilitate better conversations with financial advisors.</p>
                </div>
              </div>
              <img className="img" src={MorganStanley} alt="Morgan Stanley project cover" />
              </div>

            <div id="samana" className="project" onClick={()=>window.open("/samana","_self")}>
              <div className="item">
                <div>
                  <h3 style={{marginBottom: "8px"}}>Samana</h3>
                  <h5>May 2020 - Jan 2021</h5>
                </div>
                <div>
                  <p>Co-founded and crafted the UX for Samana.lk, Sri Lanka's first trilingual nonprofit online catalog of mental health services. This trilingual nonprofit venture strives to improve access to mental health services across the nation.</p>
                </div>
              </div>
              <img className="img" src={Samana} alt="Samana project cover" />
              </div>
          </div>
        <div className="projects-title">
            <h5>Past work</h5><img src={ArrowDown} alt="Arrow down" style={{ width: "24px", marginLeft: "8px"}} />
        </div>

          <div className="project-list">
            <div className="row">
              <div id="go studio fx" className="project" onClick={()=>window.open("/go-studio-fx","_self")}>
                <div className="item">
                  <div>
                    <h3 style={{marginBottom: "8px"}}>Go Studio FX</h3>
                    <h5>Jul 2018 - Dec 2019</h5>
                  </div>
                  <div>
                    <p>Lead designer on Go Studio FX, which is an iOS app that enables artists to create music videos using TC Helicon's famous audio effects, and share with their fans.</p>
                  </div>
                </div>
                <img className="img" src={GoStudioFX} alt="Go Studio FX project cover" />
                </div>
                <div id="blender" className="project" onClick={()=>window.open("/blender","_self")}>
                  <div className="item">
                    <div>
                      <h3 style={{marginBottom: "8px"}}>Blender</h3>
                      <h5>May 2018 - Aug 2018</h5>
                    </div>
                    <div>
                      <p>Lead designer on the companion app for Blender, which is an audio mixer where bands can plug in their instruments and jam quietly with headphones.</p>
                    </div>
                  </div>
                  <img className="img" src={Blender} alt="Blender project cover" />
                </div>
            </div>

            <div className="row">
              <div id="goxlr" className="project" onClick={()=>window.open("/go-xlr","_self")}>
                <div className="item">
                  <div>
                    <h3 style={{marginBottom: "8px"}}>GoXLR</h3>
                    <h5>May 2018 - Oct 2018</h5>
                  </div>
                  <div>
                    <p>Lead designer on the GoXLR companion app on Windows. GoXLR is the first XLR based streaming audio mixer designed specifically for streamers.</p>
                  </div>
                </div>
                <img className="img" src={GoXLR} alt="GoXLR project cover" />
                </div>
                <div className="project" onClick={()=>window.open("/teamwrk","_self")}>
                  <div className="item">
                    <div>
                      <h3 style={{marginBottom: "8px"}}>Teamwrk</h3>
                      <h5>May 2017 - Sep 2017</h5>
                    </div>
                    <div>
                      <p>Teamwrk is the first ever product I designed professionally. It is a white labbeled CRM platform where clients can manage internal communications and files within thier organization.</p>
                    </div>
                  </div>
                  <img className="img" src={Teamwrk} alt="Teamwrk project cover" />
                </div>
            </div>
          </div>

          

        </div>
        
  </>;
};

export default Work