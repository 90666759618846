import React from "react";
import { Link } from 'react-router-dom';
import './copilot-rich-artifacts.scss';
import Cover from "../../assets/images/copilot-rich-artifacts/copilot-rich-artifacts-cover.png"
import CodeBlocks from "../../assets/images/loop-code-blocks/loop-code-blocks-cover.png"
import ArrowRight from "../../assets/icons/arrow-right.svg"


const CopilotRichArtifacts = () => {
  return <div className="page">
    <div className="intro">
      <div>
        <div id="heading">
          <h1 style={{marginBottom: "16px"}}>Rich Artifacts in Copilot Pages</h1>
          <h5>Lead Product designer / Sep 2024 - Present</h5>
        </div>
        </div>
          <img className="img" src={Cover} alt="Rich artifacts in Copilot Pages cover" />
          <div id="paragraph" className="text-paragraph">
            <p>This new features allows users to prompt Copilot to generate rich artifacts such as Interactive charts, Mermaid diagrams, code blocks, tables, and more. Users can also add generated artifacts to a Page to edit with their team in real time.</p>
            <p>As one of two lead designers on the rich artifacts project for <Link className="link" to="https://techcommunity.microsoft.com/blog/microsoft365copilotblog/announcing-copilot-pages-for-multiplayer-collaboration/4242701" target='_blank'>Copilot Pages</Link>, my role encompasses concept development, creating design prototypes for testing and research, and producing detailed design specifications for visual design, interactions, and accessibility.</p>
            <p>The work is currently in development and will be released in early 2025.</p>
          </div>
        </div>

      <div id="anouncement" className="even-grid">
        <div>
          <h3 style={{padding: "12px 0"}}>Ignite 2024 announcement</h3>
          <p>Rich artifacts in Copilot Pages was annouced to the public in Satya Nadella's keynote at Ignite 2024. It was also covered in Microsoft EVP Rajesh Jha's keynote. <Link className="link" to="https://www.microsoft.com/en-us/microsoft-365/blog/2024/11/19/introducing-copilot-actions-new-agents-and-tools-to-empower-it-teams/" target='_blank'>More details.</Link></p>
        </div>
        <iframe className="youtube-video" src="https://www.youtube.com/embed/3YiB2OvK6sY?si=351hbyiOKb04L3JW&amp;start=815" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
      </div>

      <div id="Interested to see more?" className="see-more">
        <h3 style={{padding: "12px 0"}}>Interested to see more?</h3>
        <p>This work is under NDA and I am limited to what I can share publicly until it is shipped to general audience. If you are interested about hearing more, please feel free to reach out.</p>
      </div>

      <div className="next">
          <h3 style={{padding: "12px 0", marginBottom: "24px"}}>Up next</h3>
          <div className="next-grid">
            <img className="img" src={CodeBlocks} alt="Loop code blocks project"/>
            <div>
              <h6>Loop code blocks</h6>
              <Link className="explore" to="/loop-code-blocks" style={{ color: "var(--brand-foreground)"}}>Explore<img src={ArrowRight} alt="Arrow right" style={{width: "24px", marginLeft: "8px"}} /></Link>
            </div>
          </div>
      </div>
  </div>;
};

export default CopilotRichArtifacts