import React from 'react';
import "./dialog.scss"

const Dialog = ({ onClose, children }) => {
  return (
    <div className="dialog">
      <div className="dialog-content">
      <h1 style={{paddingBottom: "40px"}}>Password required to access this content</h1>
        {children}
        <button onClick={onClose}>Enter</button>
      </div>
    </div>
  );
};

export default Dialog;