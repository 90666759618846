import React from "react";
import { Link, ScrollRestoration } from 'react-router-dom';
import './loop-code-blocks.scss';
import LoopPaywall from "../../assets/images/loop-paywall/loop-paywall-cover.png"
import Cover from "../../assets/images/loop-code-blocks/loop-code-blocks-cover.png"
import Context from "../../assets/images/loop-code-blocks/loop-code-blocks-context.gif"
import LineCount from "../../assets/images/loop-code-blocks/line-count.png"
import Prototype from "../../assets/images/loop-code-blocks/prototype.gif"
import Feedback from "../../assets/images/loop-code-blocks/user-feedback.png"
import PrototypeBG from "../../assets/images/loop-code-blocks/prototype-bg.png"
import CodingLanguages from "../../assets/images/loop-code-blocks/coding-languages.png"
import Mermaid from "../../assets/images/loop-code-blocks/mermaid.png"
import Attribution from "../../assets/images/loop-code-blocks/attribution.png"
import IA from "../../assets/images/loop-paywall/paywall-ia.png"
import GP from "../../assets/images/loop-paywall/paywall-gp.png"
import GP2 from "../../assets/images/loop-paywall/paywall-gp2.png"
import PGP from "../../assets/images/loop-paywall/paywall-pgp.png"
import PGP2 from "../../assets/images/loop-paywall/paywall-pgp2.png"
import MSA from "../../assets/images/loop-paywall/paywall-msa.png"
import Timeline from "../../assets/images/loop-paywall/paywall-timeline.png"
import SKU from "../../assets/images/loop-paywall/paywall-sku.png"
import ArrowRight from "../../assets/icons/arrow-right.svg"

const LoopPaywallProject = () => {
  return <div className="page">
    <div className="intro">
      <div>
        <div id="heading">
          <h1 style={{marginBottom: "16px"}}>Loop code blocks</h1>
          <h5>Poduct designer / Feb 2023 - May 2023</h5>
        </div>
        </div>
          <img className="img" src={Cover} alt="Loop code blocks" />
          <div id="paragraph">
            <p>I was one of two designers involved in designing the code blocks Loop. In addition to leading the design for the mobile app experience, I was heavily involved with the delivery and concept of the web experience.</p>
          </div>
        </div>

        <div className="key-features">
        <h3>Context</h3>
        <div id="feature 1" className="features-grid-odd">
          <div className="features-grid-text-odd">
          <ul className="text-paragraph">
                <p>
                  <li>
                    One of the most highly requested features from user feedback.
                  </li>
                  <li>
                    Code blocks enable users to add code snippets to their Loop workspace and pages, notes, project wikis or documentation.
                  </li>
                  <li>
                    It can easily be shared as Loop components making it easier to collaborate with anyone anywhere.
                  </li>
                  <li>
                  Features syntax highlighting, code folding and wrapping to increase readability.
                  </li>
                  <li style={{paddingBottom: "0"}}>
                    Code blocks was first unveiled at Microsoft build 2023.
                  </li>
                </p>
              </ul>
          </div>
          <img style={{borderRadius:"1vmin"}} className="features-img" src={Context}/>
        </div>
      </div>

        <div className="key-features">
        <h3>Key Features</h3>
        <div id="feature 1" className="features-grid-odd">
          <div className="features-grid-text-odd">
            <h6>Choose between different coding languages and syntax</h6>
          </div>
          <img className="features-img" src={CodingLanguages}/>
        </div>
        <div id="feature 2" className="features-grid-even">
          <img className="features-img" src={LineCount}/>
          <div className="features-grid-text-even">
            <h6>Interactions such as copy code, and toggling line count</h6>
          </div>
        </div>
        <div id="feature 3" className="features-grid-odd">
          <div className="features-grid-text-odd">
            <h6>See attribuition for who last edited the code</h6>
          </div>
          <img className="features-img" src={Attribution}/>
        </div>
        <div id="feature 4" className="features-grid-even">
          <img className="features-img" src={Mermaid}/>
          <div className="features-grid-text-even">
            <h6>Support for code visualizing languages such as Mermaid</h6>
          </div>
        </div>
      </div>

        {/* <div className="key-features">
        <h3>User research</h3>
        <div id="feature 1" className="features-grid-odd">
          <div className="features-grid-text-odd">
          <ul className="text-paragraph">
                <p>
                  <li>
                    Highly requested feature by our users, and is a Loop adoption blocker for many of them.
                  </li>
                  <li>
                    Request from internal teams such as Clipchamp engineers to write engineering design docs in Loop before and and engineering documentation after a project.
                  </li>
                  <li style={{paddingBottom: "0"}}>
                    Data from Copilot studies showed that using Copilot to generate and translate code via code blocks can be transformative.
                  </li>
                </p>
              </ul>
          </div>
          <img className="features-img" src={Feedback}/>
        </div>
      </div>

      <div id="prototype">
        <div>
          <h3 style={{padding: "12px 0"}}>We started by designing end to end flows</h3>
        </div>
        <div className="even-grid">
        <img style={{maxWidth: "200px", padding: "40px 0 40px 0"}} className="img" src={Prototype}/>
        </div>
      </div> */}


      <div className="next">
          <h3 style={{padding: "12px 0", marginBottom: "24px"}}>Up next</h3>
          <div className="next-grid">
            <img className="img" src={LoopPaywall} alt="Loop Paywall" />
            <div>
              <h6>Loop Paywall</h6>
              <Link className="explore" to="/loop-paywall">Explore<img src={ArrowRight} alt="Arrow right" style={{width: "24px", marginLeft: "8px"}} /></Link>
            </div>
          </div>
      </div>
  </div>;
};

export default LoopPaywallProject